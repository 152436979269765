$(document).ready(function(){

    // Fixed header
    enquire.register('screen and (min-width: 61.9em)', {
        deferSetup: true,
        match : function(){
            
            $(window).on('scroll touchmove', function(){
        
                var scrollTop = $(window).scrollTop();
                
                if( scrollTop > 200 ){
                    $('html').addClass('fixed-header');
                } else {
                    $('html').removeClass('fixed-header');
                }
        
                if( scrollTop > 300 ){
                    $('html').addClass('fixed-header-transition');
                } else {
                    $('html').removeClass('fixed-header-transition');
                }
                
                if( scrollTop > 550 ){
                    $('html').addClass('show-fixed-header');
                } else {
                    $('html').removeClass('show-fixed-header');
                }
        
            }).trigger('scroll');

        },
        unmatch : function(){

            $(window).off('scroll touchmove');

            $('html').removeClass('fixed-header fixed-header-transition show-fixed-header');

        }
    }, true);


    // Aside menu
    $('.aside__toggle').on('click', function(){
        $('html').toggleClass('aside-closed');

        setTimeout(function(){
            $(window).trigger('resize');
        }, 400);
    });

    $('.aside__list--button').on('click', function(){
        $(this).parents('.has-sub-items').toggleClass('has-sub-items-active');
    });


    // Scrolltable
    if( $('.scroll-table').length > 0 ){
        $('.scroll-table').scroltable();
    }


    // Paging table
    if( $('.paging-table').length > 0 ){
        $('.paging-table').each(function(i,e){

            $(e).fancyTable({
                inputPlaceholder: $(e).data('placeholder'),
                perPage: $(e).data('per-page'),
                globalSearch: $(e).data('search'),
                searchable: true,
                sortable: $(e).data('sort')
            });

            $(document).on('change', 'select', e, function(){
                
                $(e).fancyTable({
                    inputPlaceholder: $(e).data('placeholder'),
                    perPage: this.value,
                    globalSearch: $(e).data('search'),
                    searchable: true,
                    sortable: $(e).data('sort')
                });
                
            });

        });
    }


    // Tooltip
    if( $('.tippy').length > 0 ){
        tippy('.tippy', {});
    }


    // Expandable box
    $('.expandable-box__header').on('click', function(){
        $(this).parents('.expandable-box').toggleClass('expanded').find('.expandable-box__body').slideToggle(250, function(){
            $(this).parents('.expandable-box').removeClass('expandable-box__expanded');
        });
    });


    // Expandable documents
    $('.documents-box__expander').on('click', function(){
        $(this).parents('.documents-box').toggleClass('expanded').find('.documents-box__body').slideToggle(250, function(){
            $(this).parents('.documents-box').removeClass('documents-box__expanded');
        });
    });


    // Select placeholder
    var addClass = function(el, className){
        if (el.classList) {
              el.classList.add(className);
        } else {
              el.className += ' ' + className;
        }
    },
    hasClass = function(el, className){
        return el.classList ?
            el.classList.contains(className) :
            new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    },
    removeClass = function(el, className){
        if( el.classList ){
            el.classList.remove(className);
        } else {
            el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    },
    updateSelectPlaceholderClass = function(el){
        var opt = el.options[el.selectedIndex];
        if( hasClass(opt, 'placeholder') ){
            addClass(el, 'placeholder');
        } else {
            removeClass(el, 'placeholder');
        }             
    },
    selectList = document.querySelectorAll('select');
    
    for( var i = 0; i < selectList.length; i++ ){
        var el = selectList[i];
        updateSelectPlaceholderClass(el);
        el.addEventListener('change', function(){
            updateSelectPlaceholderClass(this);
        });
    }

});
